import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InfoEstudiante from '../InfoEstudiante/InfoEstudiante.js';
import InfoLandlord from '../InfoLandlord/InfoLandlord.js';
import Slide from "@material-ui/core/Slide";
import './Body.css';

export default class Body extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            timeOut: 0,
            showStudent:true,
            showLandlord:false
        };
        this.clickStudent = this.clickStudent.bind(this);
        this.clickLandlord = this.clickLandlord.bind(this);
      }

    clickStudent= ()=>{
        if(!this.state.showStudent){
            this.setState({
                timeOut:100,
            });
            this.setState({
                showStudent:true,
            });
            this.setState({
                showLandlord:false,
            });
        }
    }

    clickLandlord=()=>{
        if(!this.state.showLandlord){
            this.setState({
                timeOut:100,
            });
            this.setState({
                showStudent:false,
            });
            this.setState({
                showLandlord:true,
            });
        }
    }

    render() {
        var style = {
            selected:{
                backgroundColor: "#DCAA00 !important",
                color:"white !important"
            },
            unselected:{
                backgroundColor: "white !important",
                color:"#DCAA00 !important" 
            }
        };
      
        return (
            <div class="containerBody">
                <Grid className="buttonsContainer" container spacing={3}>
                    <Grid className="studentButton" item xs={6}>
                    <div class="cuartoExtra2">
                            .
                        </div>
                        <Button className={this.state.showStudent?"selectedButton":"unselectedButton "} variant="contained" onClick={this.clickStudent}>
                            Soy estudiante
                        </Button>
                    </Grid>
                    <Grid className="landlordButton" item xs={6}>
                        <div class="cuartoExtra">
                            ¿Tienes un cuarto extra?
                        </div>
                        <Button className={this.state.showLandlord?"selectedButton":"unselectedButton"} variant="contained" onClick={this.clickLandlord} >
                            ¡Réntalo!
                        </Button>
                    </Grid>
                </Grid>

                <Slide
                    in={this.state.showStudent}
                    timeout={this.state.timeOut}
                    direction="right"
                    mountOnEnter unmountOnExit
                >
                     {this.state.showStudent?<InfoEstudiante />: <div></div>}
                </Slide>
                <Slide
                    in={this.state.showLandlord}
                    timeout={this.state.timeOut}
                    direction="left"
                    mountOnEnter unmountOnExit
                >
                    {this.state.showLandlord?<InfoLandlord />: <div></div>}
                </Slide>

            </div>
        )
    }
}
