import React, { Component } from 'react'
import Header from '../../../movil/Components/Header/Header.js';
import Body from '../../../movil/Components/Body/Body.js';
import './LandingMovil.css';

export default class LandingMovil extends Component {
    render() {
        return (
            <div class="containerLanding">
                <Header/>
                <Body/>
            </div>
        )
    }
}
