import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import logo from '../../../assets/logo.png';
import './Header.css';

export default class Header extends Component {
    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid className="logoContainer" item xs={12}>
                        <img align="right" src={logo} alt="logo" height="90px" width="170" className="logo"/>
                    </Grid>
                    <Grid className="textHeader" item xs={12}>
                        Somos una plataforma<br/> de renta de cuartos y casas para estudiantes
                    </Grid>
                </Grid>
            </div>
        )
    }
}
