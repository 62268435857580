import React, { Component } from 'react';
import './UsersBin.css';
import Grid from '@material-ui/core/Grid';
import student from '../../assets/student.png';
import owner from '../../assets/owner.png';
import Button from '@material-ui/core/Button';

export default class UsersBin extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="users-bin-container">
                <Grid container spacing={0}>
                    <Grid xs={12} sm={6} className="grid-container">
                        <div className="contain">
                            <img alt="student" src = {student} className="images"/>
                            <div align="center" className="first-grid-container">
                                <div className="title">
                                    Soy estudiante y busco un <br/>hogar
                                </div>
                                <div className="description">
                                    Queremos que encuentres un hogar cerca de tu escuela, con las características que tu <br/>buscas.
                                </div>
                                <Button variant="contained" onClick={this.props.onclick} className={this.props.user? "button-selected": "button"}>
                                    Estudiante
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={6} className="grid-container">
                        <div className="contain">
                            <img alt="owner" src = {owner} className="images"/>
                            <div align="center" className="first-grid-container">
                                <div className="title">
                                    Tengo una propiedad y quiero rentar
                                </div>
                                <div className="description">
                                    Queremos ayudarte a publicar tu cuarto o casa para que estudiantes puedan rentarla a través de nuestra plataforma. 
                                </div>
                                <Button variant="contained" onClick={this.props.onclick} className={!this.props.user? "button-selected": "button"}>
                                    Dueño
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
