import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import './InfoLandlord.css';
import buscar from '../../assets/buscar.png';
import reservar from '../../assets/reservar.png';
import pagar from '../../assets/pagar.png';
import carro from '../../assets/carro.png';

export default class InfoLandlord extends Component {
    render() {
        return (
            <div>
                <Grid  container spacing={3}>
                    <Grid className="titulo" item xs={12}>
                        Tan fácil como
                    </Grid>
                    <Grid className="imagen1" item xs={6}>
                        <img align="right" src={buscar} alt="logo" height="130px" width="170" className="logo"/>
                    </Grid>
                    <Grid className="contentText22" item xs={6}>
                        Publicar tu cuarto en nuestra plataforma
                    </Grid>
                    <Grid className="contentText11" item xs={6}>
                        Acepta una solicitud de reserva
                    </Grid>
                    <Grid className="imagen2"  item xs={6}>
                        <img align="right" src={reservar} alt="logo" height="130px" width="160" className="logo"/>
                    </Grid>
                    <Grid className="imagen1"  item xs={6}>
                        <img align="right" src={pagar} alt="logo" height="130px" width="170" className="logo"/>
                    </Grid>
                    <Grid className="contentText22" item xs={6}>
                        Recibe tu dinero mensualmente
                    </Grid>
                    <Grid className="titulo" item xs={12}>
                        Nuestros Beneficios
                    </Grid>

                    <Grid  className="icono" item xs={2}>
                    <i class="fa fa-money fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText" item xs={10}>
                        Pago automático mensual
                    </Grid>
                    <Grid  className="icono"  item xs={2}>
                        <i className="fa fa-lock  fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText" item xs={10}>
                       Contrato en linea
                    </Grid>
                    <Grid  className="icono" item xs={2}>
                        <i className="fa fa-check-square  fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText" item xs={10}>
                       Estudiantes verificadas
                    </Grid>
                    <Grid className="icono" item xs={2}>
                        <i className="fa  fa-comments  fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText" item xs={10}>
                        Servicio de mensajería
                    </Grid>
                    <Grid className="icono" item xs={2}>
                        <i className="fa  fa-camera  fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText" item xs={10}>
                        Servicio de fotografía profesional
                    </Grid>
                    <Grid className="icono" item xs={2}>
                        <i className="fa fa-credit-card-alt fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText" item xs={10}>
                        Cobro del depósito de seguridad para poder realizar una reservación
                    </Grid>
                    <Grid item xs={12}>
                        <Button className="datosBoton" variant="contained" href="#form">
                            Déjanos tus datos
                        </Button>
                    </Grid>

                    <Grid className="textoBoton" item xs={12}>
                        Te avisaremos cuando  <br></br>nuestra App esté lista <br></br>
                    </Grid>
                    <Grid item xs={12}>
                        <iframe className="form" id="form" title="google-form" src="https://docs.google.com/forms/d/e/1FAIpQLSdO_c4NjSXOcAwYXciFNo2xQzdsROHprt4fPx-ENUz7tsOfmQ/viewform?embedded=true" width="100%" height="1440px" frameborder="0" marginheight="0" marginWidth="0">Loading…</iframe>

                    </Grid>
                </Grid>
            </div>
        )
    }
}
