import React from 'react';
import './App.css';
import Landing from './Views/Landing/Landing.js';
import LandingMovil from './Views/Landing/Movil/LandingMovil.js';
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? <Landing/> : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? <LandingMovil/>  : null
}

function App() {
  return (
    <div className="App">
      <Desktop/>
      <Mobile/>
    </div>
  );
}

export default App;
