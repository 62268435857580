import React, { Component } from 'react';
import './WelcomeBin.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import image from '../../assets/home.jpg';
import logo from '../../assets/logo.png';

export default class WelcomeBin extends Component {
    handleClick(){
        window.scrollTo({
            top: 1950,
            behavior: 'smooth',
        });
    }
    render() {
        return (
            <div className="welcome-container">
                <div className="nav">
                    <img align="left" src={logo} alt="logo" className="logo"/>
                </div>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} className="grid-container">
                        <div align="left" className="first-grid-container">
                            <div className="title">
                                Encuentra tu nuevo hogar
                            </div>
                            <div className="description">
                                Somos una plataforma de renta de cuartos y casas para estudiantes. 
                            </div>
                            <Button variant="contained" className="button" onClick={this.handleClick.bind(this)}>
                                Contáctanos
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img align="right" className="image" alt="house" src={image}/>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
