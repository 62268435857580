import React, { Component } from 'react';
import './CarouselCards.css';

export default class CarouselCards extends Component {
    render() {
        return (
            <div className="card-container">
                <img className="image" alt="card" src={this.props.img}/>
                <div className="title">{this.props.name}</div>
            </div>
        )
    }
}
