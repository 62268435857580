import React, { Component } from 'react';
import WelcomeBin from '../../Components/Welcome-Bin/WelcomeBin.js';
import FormBin from '../../Components/Form-Bin/FormBin.js';
import UsersBin from '../../Components/Users-Bin/UsersBin.js';
import Carousel from '../../Components/Carousel/Carousels.js';
import search from '../../assets/serach.png';
import reserve from '../../assets/reserve.png';
import pay from '../../assets/pay.png';
import move from '../../assets/move.png';
import './Landing.css';

export default class Landing extends Component {
    state = {
        user: true,
        student: [
            {
                img: search,
                name: "Buscar",
            },
            {
                img: reserve,
                name: "Reservar",
            },
            {
                img: pay,
                name: "Pagar",
            },
            {
                img: move,
                name: "Mudarte",
            }
        ],
        owner: [
            {
                img: search,
                name: "Publicar tus cuartos en nuestra plataforma",
            },
            {
                img: reserve,
                name: "Aceptar una solicitud de reserva",
            },
            {
                img: pay,
                name: "Recibe tu dinero mensualmente",
            }
        ]
    }
    render() {
        const onClick = (e) => {
            console.log(e.target.textContent);
            if (e.target.textContent === "Estudiante"){
                this.setState({
                    user: true
                })
            } else {
                this.setState({
                    user: false
                })
            }
        }
        return (
            <div class="mainDiv">
                <WelcomeBin/>
                <UsersBin onclick = {onClick} user={this.state.user}/>
                <Carousel user={this.state.user} items={this.state.user? this.state.student: this.state.owner}/>
                <FormBin/>
            </div>
        )
    }
}
