import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import './InfoEstudiante.css';
import buscar from '../../assets/buscar.png';
import reservar from '../../assets/reservar.png';
import pagar from '../../assets/pagar.png';
import carro from '../../assets/carro.png';

export default class InfoEstudiante extends Component {
    render() {
        return (
            <div>
                <Grid  container spacing={3}>
                    <Grid className="titulo1" item xs={12}>
                        Tan fácil como
                    </Grid>
                    <Grid className="contentText1" item xs={6}>
                        <img align="right" src={buscar} alt="logo" height="130px" width="170" className="logo"/>
                        Buscar
                    </Grid>
                    <Grid className="contentText2"  item xs={6}>
                        <img align="right" src={reservar} alt="logo" height="130px" width="160" className="logo"/>
                        Reservar
                    </Grid>
                    <Grid className="contentText1"  item xs={6}>
                        <img align="right" src={pagar} alt="logo" height="130px" width="170" className="logo"/>
                        Pagar
                    </Grid>
                    <Grid className="contentText2"  item xs={6}>
                        <img align="right" src={carro} alt="logo" height="130px" width="170" className="logo"/>
                        Mudarse
                    </Grid>
                    <Grid className="titulo1" item xs={12}>
                        Nuestros Beneficios
                    </Grid>
                    <Grid  className="icono1" item xs={2}>
                        <i className="fa fa-home fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText1" item xs={10}>
                        Muchas propiedades disponibles
                    </Grid>
                    <Grid  className="icono1"  item xs={2}>
                        <i className="fa fa-mouse-pointer  fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText1" item xs={10}>
                       Reserva Facil
                    </Grid>
                    <Grid className="icono1" item xs={2}>
                        <i className="fa fa-credit-card-alt fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText1" item xs={10}>
                        Diferentes métodos de pago
                    </Grid>
                    <Grid  className="icono1" item xs={2}>
                        <i className="fa fa-check-square  fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText1" item xs={10}>
                       Propiedades verificadas
                    </Grid>
                    <Grid className="icono1" item xs={2}>
                        <i className="fa  fa-comments  fa-lg"></i>
                    </Grid>
                    <Grid className="beneficiosText1" item xs={10}>
                        Mensajería con el dueño
                    </Grid>
                    <Grid item xs={12}>
                        <Button className="datosBoton" variant="contained" href="#form">
                            Déjanos tus datos
                        </Button>
                    </Grid>

                    <Grid className="textoBoton" item xs={12}>
                        Te avisaremos cuando  <br></br>nuestra App esté lista <br></br>
                    </Grid>
                    <Grid item xs={12}>
                        <iframe className="form" id="form" title="google-form" src="https://docs.google.com/forms/d/e/1FAIpQLSdO_c4NjSXOcAwYXciFNo2xQzdsROHprt4fPx-ENUz7tsOfmQ/viewform?embedded=true" width="100%" height="1440px" frameborder="0" marginheight="0" marginWidth="0">Loading…</iframe>

                    </Grid>
                </Grid>
            </div>
        )
    }
}
