import React, { Component } from 'react';
import Carousel from 'react-material-ui-carousel';
import CarouselCards from '../CarouselCards/CarouselCards.js';
import './Carousel.css';
import Grid from '@material-ui/core/Grid';

export default class Carousels extends Component {
    render() {
        return (
            <Grid container spacing={0} className="carousel">
                <Grid item xs={4} className={this.props.user? "non-display" : "grid-container margin"}>
                    <div>
                        <Grid className="titulo" item xs={12}>
                            Nuestros Beneficios
                        </Grid>
                        <div className="flex">
                            <Grid  className="icono" item xs={2}>
                            <i class="fa fa-money fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText" item xs={10}>
                                Pago automático mensual
                            </Grid>
                        </div>
                        <div className="flex">
                            <Grid  className="icono"  item xs={2}>
                                <i className="fa fa-lock  fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText" item xs={10}>
                            Contrato en linea
                            </Grid>
                        </div>
                        <div className="flex">
                            <Grid  className="icono" item xs={2}>
                                <i className="fa fa-check-square  fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText" item xs={10}>
                            Estudiantes verificados
                            </Grid>
                        </div>
                        <div className="flex">
                            <Grid className="icono" item xs={2}>
                                <i className="fa  fa-comments  fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText" item xs={10}>
                                Servicio de mensajería
                            </Grid>
                        </div>
                        <div className="flex">
                            <Grid className="icono" item xs={2}>
                                <i className="fa  fa-camera  fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText" item xs={10}>
                                Servicio de fotografía profesional
                            </Grid>
                        </div>
                        <div className="flex">
                            <Grid className="icono" item xs={2}>
                                <i className="fa fa-credit-card-alt fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText" item xs={10}>
                                Cobro del depósito de seguridad para poder realizar una reservación
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <Carousel
                    interval={2000}
                    >
                        {
                            this.props.items.map( (item, i) =>{
                                return (
                                    <CarouselCards 
                                    key={i}
                                    img={item.img}
                                    name={item.name}
                                    description={item.description}/>
                                )
                            })
                        }
                    </Carousel>
                </Grid>
                <Grid item xs={4} className={!this.props.user? "non-display" : "grid-container"}>
                    <div>
                        <Grid className="titulo">
                            Nuestros Beneficios
                        </Grid>
                        <div className="flex">
                            <Grid  className="icono">
                                <i className="fa fa-home fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText">
                                Muchas propiedades disponibles
                            </Grid>
                        </div>
                        <div className="flex">
                            <Grid  className="icono">
                                <i className="fa fa-mouse-pointer fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText">
                            Reserva Facil
                            </Grid>
                        </div>
                        <div className="flex">
                            <Grid  className="icono">
                                <i className="fa fa-check-square fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText">
                            Propiedades verificadas
                            </Grid>
                        </div>
                        <div className="flex">
                            <Grid className="icono">
                                <i className="fa  fa-comments fa-lg"></i>
                            </Grid>
                            <Grid className="beneficiosText">
                                Mensajería con el dueño
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    }
}
