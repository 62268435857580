import React, { Component } from 'react'
import './FormBin.css';
import Grid from '@material-ui/core/Grid';

export default class FormBin extends Component {
    render() {
        return (
            <div href="form" className="form-bin-container">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <iframe className="form" title="google-form" src="https://docs.google.com/forms/d/e/1FAIpQLSdO_c4NjSXOcAwYXciFNo2xQzdsROHprt4fPx-ENUz7tsOfmQ/viewform?embedded=true" width="640" height="1217" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                    </Grid>
                    <Grid item xs={12} sm={6} className="grid-container">
                        <div align="left" className="first-grid-container">
                            <div className="title">
                                Déjanos tus Datos!
                            </div>
                            <div className="description">
                                Te avisaremos cuando nuestra App esté lista. 
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
